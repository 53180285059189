import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import HeaderPrimary from '../components/Header/HeaderPrimary'
import Footer from '../components/Footer'
import Video from '../components/Media/MediaVideo'
import { applyTheme, palette } from '../utils/theme'

class AboutPage extends React.Component {
  componentDidMount = () => {
    applyTheme(palette.light)
  }

  render() {
    const { location, data } = this.props
    const pageData = data.prismicAbout.data
    return (
      <Layout location={location}>
        <SEO
          title={pageData.meta_title || 'About'}
          description={pageData.meta_description || ''}
          location={location}
        />
        <HeaderPrimary />
        <div className="pt-60vh">
          <div className="js-scroll-target js-transition-link-target">
            <div className="px-gs">
              <div className="flex flex-wrap justify-end md:justify-between mb-10 lg:mb-30 -ml-gs">
                <div className="pl-gs w-full md:w-8/12 mb-20 md:mb-0">
                  {pageData.content.html && (
                    <div
                      className="rte text-xl md:text-3xl 2xl:text-4xl"
                      dangerouslySetInnerHTML={{
                        __html: pageData.content.html,
                      }}
                    ></div>
                  )}
                </div>
                <div className="pl-gs w-6/12 md:w-3/12">
                  <div className="text-sm 2xl:text-base">
                    <h4 className="mb-4">Our Capabilities</h4>
                    <ul>
                      {pageData.capabilities &&
                        pageData.capabilities
                          .filter(item => item.capability.document)
                          .map((item, index) => (
                            <li key={index}>
                              {item.capability.document.data.title}
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
              </div>

              {pageData.image.fluid && (
                <div className="mb-12 md:mb-15 lg:mb-30">
                  <Img
                    fluid={pageData.image.fluid}
                    alt={pageData.image.alt}
                    loading="eager"
                  />
                </div>
              )}

              {pageData.video_url && (
                <div className="mb-12 md:mb-15 lg:mb-30">
                  <Video videoUrl={pageData.video_url} />
                </div>
              )}

              {pageData.recent_clients && (
                <div className="rte text-sm 2xl:text-base">
                  <h4 className="mb-4">Recent Clients</h4>
                  <ul className="columns">
                    {pageData.recent_clients
                      .filter(item => item.client)
                      .map((item, index) => (
                        <li key={index}>{item.client}</li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    )
  }
}

AboutPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const aboutPageQuery = graphql`
  query {
    prismicAbout {
      data {
        title
        meta_title
        meta_description
        content {
          html
        }
        image {
          fluid(
            imgixParams: { q: 100 }
            srcSetBreakpoints: [640, 768, 1024, 1366, 1600, 1920, 2500]
          ) {
            srcSetWebp
            srcWebp
            sizes
            src
            srcSet
            base64
            aspectRatio
          }
          alt
        }
        video_url
        recent_clients {
          client
        }
        capabilities {
          capability {
            document {
              ... on PrismicWorkType {
                data {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPage
